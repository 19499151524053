import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userAPI } from '../../api';
import LoadingCenter from '../Helper/LoadingCenter';

const Callback = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [logArray, setLogArray] = useState(['Starting eBay authentication process...']);

  const addLog = (msg) => {
    setLogArray(prev => [...prev, msg]);
  };

  useEffect(() => {
    const sendCodeToBackend = async () => {
      try {
        addLog(`Processing eBay authentication with code: ${code?.substring(0, 8)}...`);
        console.log('Processing eBay callback:', { code, state });
        
        const response = await userAPI.ebayCallback({ code, state });
        addLog('eBay callback successful');
        addLog(`Server response: ${response.message}`);
        setMessage(response.message);
        setLoading(false);
      } catch (error) {
        const errorMsg = error?.response?.data?.message || 'An unexpected error occurred';
        console.error('eBay callback error:', error);
        addLog(`Error: ${errorMsg}`);
        setError(errorMsg);
        setLoading(false);
      }
    };

    sendCodeToBackend();
  }, [code, state]);

  const handleCloseWindow = () => {
    window.close();
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card mt-5">
            <div className="card-header">
              <h5 className="mb-0">eBay Authentication Log</h5>
            </div>
            <div className="card-body" style={{ height: '300px', overflowY: 'auto' }}>
              {logArray.map((log, index) => (
                <div key={index} className="mb-2">
                  <code>{log}</code>
                </div>
              ))}
            </div>
            <div className="card-footer">
              {loading ? (
                <div className="text-center">
                  <LoadingCenter />
                  <p className="mt-3">Processing...</p>
                </div>
              ) : (
                <div>
                  {error ? (
                    <div className="alert alert-danger mb-3">
                      Authentication failed: {error}
                    </div>
                  ) : (
                    <div className="alert alert-success mb-3">
                      Authentication successful!
                    </div>
                  )}
                  <button className="btn btn-primary" onClick={handleCloseWindow}>
                    Close Window
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Callback;